import * as yup from 'yup';

import {
  cellPhoneRegexp,
  EMAIL_LENGTH,
  emailRegexp,
  ERROR_MSG,
} from 'constants/validation';
import { CountriesStatesSelect } from 'constants/settings.constants';

export const customerSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  lastName: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  email: yup
    .string()
    .trim()
    .matches(emailRegexp, ERROR_MSG.email)
    .max(EMAIL_LENGTH, ERROR_MSG.max(80))
    .required(ERROR_MSG.required),
  phone: yup
    .string()
    .trim()
    .matches(cellPhoneRegexp, ERROR_MSG.cellPhone)
    .optional()
    .transform((value) => (value ? value : undefined)),
  companyName: yup
    .string()
    .max(50, ERROR_MSG.max(50))
    .trim()
    .transform((value) => (value ? value : undefined)),
  address: yup.object({
    address: yup
      .string()
      .trim()
      .min(1, ERROR_MSG.min(1))
      .max(255, ERROR_MSG.max(255))
      .required(ERROR_MSG.required),
    city: yup
      .string()
      .trim()
      .min(1, ERROR_MSG.min(1))
      .max(255, ERROR_MSG.max(255))
      .required(ERROR_MSG.required),
    zip: yup
      .string()
      .trim()
      .min(4, ERROR_MSG.min(4))
      .max(6, ERROR_MSG.max(6))
      .required(ERROR_MSG.required),
    country: yup
      .string()
      .min(2, ERROR_MSG.min(2))
      .max(2, ERROR_MSG.max(2))
      .trim()
      .required(ERROR_MSG.required),
    state: yup
      .string()
      .trim()
      .max(255, ERROR_MSG.max(255))
      .optional()
      .transform((value) => (value ? value : undefined))
      .when('country', (country, field) => {
        return country[0]?.toString() === CountriesStatesSelect.CA ||
          country[0]?.toString() === CountriesStatesSelect.US
          ? field.required(ERROR_MSG.required)
          : field.optional();
      }),
  }),
});
