import * as yup from 'yup';
import {
  certificateNumberRegexp,
  EMAIL_LENGTH,
  ERROR_MSG,
  emailRegexp,
  cellPhoneRegexp,
  noDoubleDashes,
  CELL_PHONE_LENGTH,
} from 'constants/validation';

import { FIELDS, WHAT_INDUSTRY_VALUES, HOW_MANY_YEARS_VALUES } from './signup.constants';

export const signUpFormSchema = yup.object({
  [FIELDS.firstName.name]: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  [FIELDS.lastName.name]: yup
    .string()
    .trim()
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  [FIELDS.email.name]: yup
    .string()
    .trim()
    .matches(emailRegexp, ERROR_MSG.email)
    .max(EMAIL_LENGTH, ERROR_MSG.max(80))
    .required(ERROR_MSG.required),
  [FIELDS.whatIndustry.name]: yup.string().required(ERROR_MSG.required),
  [FIELDS.cellPhone.name]: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .matches(cellPhoneRegexp, { message: ERROR_MSG.cellPhone, excludeEmptyString: true })
    .matches(noDoubleDashes, ERROR_MSG.cellPhoneDoubleDashes)
    .max(CELL_PHONE_LENGTH, ERROR_MSG.max(CELL_PHONE_LENGTH))
    .required(ERROR_MSG.required),
  [FIELDS.companyName.name]: yup
    .string()
    .min(2, ERROR_MSG.min(2))
    .max(50, ERROR_MSG.max(50))
    .trim()
    .required(ERROR_MSG.required),
  [FIELDS.certificate.name]: yup
    .string()
    .max(50, ERROR_MSG.max(50))
    .matches(certificateNumberRegexp, ERROR_MSG.digitsSymbols)
    .trim()
    .when(FIELDS.whatIndustry.name, (industry, field) => {
      return industry[0]?.toString() !==
        WHAT_INDUSTRY_VALUES.PROMOTIONAL_PRODUCT_DISTRIBUTOR
        ? field.notRequired()
        : field.required(ERROR_MSG.required);
    }),
  [FIELDS.howManyYears.name]: yup.string().required(ERROR_MSG.required),
  [FIELDS.companyRevenue.name]: yup
    .string()
    .when(FIELDS.howManyYears.name, (howManyYears, field) => {
      return howManyYears[0] &&
        howManyYears[0].toString() !== HOW_MANY_YEARS_VALUES.UNDER_1Y
        ? field.required(ERROR_MSG.required)
        : field.notRequired();
    }),
});
