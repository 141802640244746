import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICustomDomain,
  IHoursOperationsBack,
  IStoreSettingsBack,
} from 'interfaces/store-creation.interfaces';
import {
  ICompany,
  IBillingAddress,
  ISocial,
  IFieldError,
  IBrand,
  IPayout,
  IInvoice,
  IUpdateFieldPayload,
  IUpdateFieldResultPayload,
  ICreditCard,
  IStorePreferences,
  IUpdateWhatWeOfferSettings,
  IHomepageSettingsDb,
  IHomepageSelectedProduct,
  ProductSortingEnum,
} from 'interfaces';
import { DEFAULT_OPERATION_HOURS_STATE, FROM, TO } from 'constants/settings.constants';
import { transformCategoriesWithSelection } from 'utils/transform-categories.util';
import { MARGIN_VALIDATION } from '../../validations/settings/products-and-margin';
import { payoutFormSchema } from '../../pages/settings/plan-and-payment/forms/payout/payout.schema';
import { invoiceFormSchema } from '../../pages/settings/plan-and-payment/forms/invoice/invoice.schema';
import { FIELDS as INVOICE_FIELDS } from '../../pages/settings/plan-and-payment/forms/invoice/invoice.contants';
import { FIELDS as PAYOUT_FIELDS } from '../../pages/settings/plan-and-payment/forms/payout/payout.contants';

const SLICE_NAME = 'settings';

export interface ISettingsSlice {
  initialCompanySettings: IStoreSettingsBack;
  companySettings: IStoreSettingsBack;
  companySettingsErrors: {
    [k in keyof IStoreSettingsBack]?: string;
  };
  initialCompany: ICompany;
  company: ICompany;
  companyErrors: {
    [k in keyof ICompany]?: string;
  };
  initialBillingAddress: IBillingAddress;
  billingAddress: IBillingAddress;
  billingAddressErrors: {
    [k in keyof IBillingAddress]?: string;
  };
  initialSocial: ISocial;
  social: ISocial;
  socialErrors: {
    [k in keyof ISocial]?: string;
  };
  initialBrand: IBrand;
  brand: IBrand;
  storePreferencesError: {
    [k in keyof IStorePreferences]?: string;
  };
  intercomAppIdError?: string;
  activeCategoryTab: number;
  initialPayout: IPayout;
  payout: IPayout;
  payoutErrors: {
    [k in keyof IPayout]?: string;
  };
  initialInvoice: IInvoice;
  invoice: IInvoice;
  invoiceErrors: {
    [k in keyof IInvoice]?: string;
  };
  initialMarginValue: string;
  marginValue: string;
  marginError?: string;
  card: ICreditCard | null;
  initialWhatWeOfferSettings: IUpdateWhatWeOfferSettings;
  whatWeOfferSettings: IUpdateWhatWeOfferSettings;
  customDomain: ICustomDomain;
  homepageSettings: IHomepageSettingsDb;
  selectedHomepageProducts: IHomepageSelectedProduct[];
}

const initialCompanySettings: IStoreSettingsBack = {
  brandName: '',
  homepageTitle: '',
  logoLinkUrl: '',
  categories: [],
  categoriesStructure: [],
  isContractSigned: false,
  logo: null,
  favicon: null,
  productsPricePoints: null,
  margin: 0.3,
  primaryColor: null,
  secondaryColor: null,
  socials: [],
  hoursOperations: [],
  intercomAppId: null,
  customCodeHeader: null,
  customCodeBody: null,
  privacyPolicyHTML: '',
  termsOfServiceHTML: '',
  customDomain: {} as ICustomDomain,
  isHomepageActive: false,
  isWhatWeOfferActive: true,
  assignmentFlowEnabled: false,
  isReviewMockupsByCustomerAllowed: true,
  isReviewPantonesByCustomerAllowed: true,
  defaultSorting: null,
};

const initialCompany: ICompany = {
  name: '',
  email: '',
};

const initialBillingAddress: IBillingAddress = {
  billingAddress: '',
  billingCountry: '',
  billingCity: '',
  billingState: '',
  billingZip: '',
};

const initialSocial: ISocial = {
  instagram: '',
  facebook: '',
  tiktok: '',
  twitter: '',
  linkedIn: '',
};

const initialBrand: IBrand = {
  primaryColor: '',
  secondaryColor: '',
  logo: undefined,
  favicon: undefined,
};

const initialPayout: IPayout = {
  payoutBankCountry: '',
  payoutBankName: '',
  payoutAccountType: '',
  payoutAccountBeneficiaryName: '',
  payoutBankAccountNumber: '',
  payoutBIC: '',
  payoutRoutingNumber: '',
  payoutComments: '',
};

const initialInvoice: IInvoice = {
  invoiceBankCountry: '',
  invoiceBankName: '',
  invoiceAccountType: '',
  invoiceAccountBeneficiaryName: '',
  invoiceBankAccountNumber: '',
  invoiceBIC: '',
  invoiceRoutingNumber: '',
  invoiceAsInPayout: false,
};

const initialState: ISettingsSlice = {
  initialCompanySettings: initialCompanySettings,
  companySettings: initialCompanySettings,
  initialCompany: initialCompany,
  company: initialCompany,
  initialBillingAddress: initialBillingAddress,
  billingAddress: initialBillingAddress,
  initialSocial: initialSocial,
  social: initialSocial,
  companyErrors: {},
  companySettingsErrors: {},
  billingAddressErrors: {},
  socialErrors: {},
  storePreferencesError: {},
  initialBrand: initialBrand,
  brand: initialBrand,
  activeCategoryTab: 0,
  initialPayout: initialPayout,
  payout: initialPayout,
  payoutErrors: {},
  initialInvoice: initialInvoice,
  invoice: initialInvoice,
  invoiceErrors: {},
  initialMarginValue: '',
  marginValue: '',
  card: null,
  whatWeOfferSettings: {},
  initialWhatWeOfferSettings: {},
  customDomain: {} as ICustomDomain,
  homepageSettings: {},
  selectedHomepageProducts: [],
};

export const updatePayout = createAsyncThunk(
  'settings/updatePayout',
  async (payload: IUpdateFieldPayload, { getState }) => {
    try {
      const state = getState() as { settings: ISettingsSlice };
      await payoutFormSchema.validateAt(payload.key, {
        [PAYOUT_FIELDS.payoutBankCountry.name]: state.settings.payout.payoutBankCountry,
        [payload.key]: payload.value,
      });
      return {
        ...payload,
        error: undefined,
      };
    } catch (e: unknown) {
      const { message } = e as { message: string };
      return {
        ...payload,
        error: message,
      };
    }
  },
);

export const updateInvoice = createAsyncThunk(
  'settings/updateInvoice',
  async (payload: IUpdateFieldPayload, { getState }) => {
    try {
      const state = getState() as { settings: ISettingsSlice };
      await invoiceFormSchema.validateAt(payload.key, {
        [INVOICE_FIELDS.invoiceBankCountry.name]:
          state.settings.invoice.invoiceBankCountry,
        [INVOICE_FIELDS.invoiceAsInPayout.name]: state.settings.invoice.invoiceAsInPayout,
        [payload.key]: payload.value,
      });
      return {
        ...payload,
        error: undefined,
      };
    } catch (e: unknown) {
      const { message } = e as { message: string };
      return {
        ...payload,
        error: message,
      };
    }
  },
);

export const settings = createSlice({
  initialState,
  name: SLICE_NAME,
  reducers: {
    setCompanySettings: (state, action: PayloadAction<IStoreSettingsBack>) => {
      const {
        categoriesStructure = [],
        hoursOperations,
        categories = [],
        ...rest
      } = action.payload;
      const categoriesStructureWithSelections = transformCategoriesWithSelection(
        categoriesStructure,
        categories,
      );

      const companySettingsData = {
        ...rest,
        categories: categoriesStructureWithSelections,
        categoriesStructure: categoriesStructure,
        hoursOperations:
          hoursOperations ?? (DEFAULT_OPERATION_HOURS_STATE as IHoursOperationsBack[]),
      };

      state.initialCompanySettings = companySettingsData;
      state.companySettings = companySettingsData;
    },
    patchCompanySettings: (state, action: PayloadAction<Partial<IStoreSettingsBack>>) => {
      state.companySettings = {
        ...state.companySettings,
        ...action.payload,
      };
      state.initialCompanySettings = {
        ...state.initialCompanySettings,
        ...action.payload,
      };
    },
    setCompany: (state, action: PayloadAction<ICompany>) => {
      state.initialCompany = action.payload;
      state.company = action.payload;
    },
    patchCompany: (state, action: PayloadAction<Partial<ICompany>>) => {
      state.company = {
        ...state.company,
        ...action.payload,
      };
    },
    setBillingAddress: (state, action: PayloadAction<IBillingAddress>) => {
      const { billingCountry, billingState, ...rest } = action.payload;
      state.initialBillingAddress = {
        billingCountry: billingCountry || initialBillingAddress.billingCountry,
        billingState: billingState || initialBillingAddress.billingState,
        ...rest,
      };
      state.billingAddress = {
        billingCountry: billingCountry || initialBillingAddress.billingCountry,
        billingState: billingState || initialBillingAddress.billingState,
        ...rest,
      };
    },
    patchBillingAddress: (state, action: PayloadAction<Partial<IBillingAddress>>) => {
      state.billingAddress = {
        ...state.billingAddress,
        ...action.payload,
      };
    },
    setPayout: (state, action: PayloadAction<IPayout>) => {
      state.initialPayout = action.payload;
      state.payout = action.payload;
    },
    setInvoice: (state, action: PayloadAction<IInvoice>) => {
      state.initialInvoice = action.payload;
      state.invoice = action.payload;
    },
    setWhatWeOfferSettings: (
      state,
      action: PayloadAction<IUpdateWhatWeOfferSettings>,
    ) => {
      state.initialWhatWeOfferSettings = action.payload;
      state.whatWeOfferSettings = action.payload;
    },
    setHomepageSettings: (state, action: PayloadAction<IHomepageSettingsDb>) => {
      state.homepageSettings = action.payload;
    },
    patchPayout: (state, action: PayloadAction<Partial<IPayout>>) => {
      state.payout = {
        ...state.payout,
        ...action.payload,
      };
    },
    patchInvoice: (state, action: PayloadAction<Partial<IInvoice>>) => {
      state.invoice = {
        ...state.invoice,
        ...action.payload,
      };
    },
    setInvoiceError: (state, action: PayloadAction<IFieldError>) => {
      state.invoiceErrors = {
        ...state.invoiceErrors,
        ...action.payload,
      };
    },
    clearInvoiceError: (state) => {
      state.invoiceErrors = {};
    },
    clearInvoice: (state) => {
      state.initialInvoice = initialInvoice;
      state.invoice = initialInvoice;
    },
    setSocial: (state, action: PayloadAction<ISocial>) => {
      state.initialSocial = action.payload;
      state.social = action.payload;
    },
    patchSocial: (state, action: PayloadAction<Partial<ISocial>>) => {
      state.social = {
        ...state.social,
        ...action.payload,
      };
    },
    setPayoutError: (state, action: PayloadAction<IFieldError>) => {
      state.payoutErrors = {
        ...state.payoutErrors,
        ...action.payload,
      };
    },
    setSocialError: (state, action: PayloadAction<IFieldError>) => {
      state.socialErrors = {
        ...state.socialErrors,
        ...action.payload,
      };
    },
    setCompanyError: (state, action: PayloadAction<IFieldError>) => {
      state.companyErrors = {
        ...state.companyErrors,
        ...action.payload,
      };
    },
    setCompanySettingsError: (state, action: PayloadAction<IFieldError>) => {
      state.companySettingsErrors = {
        ...state.companySettingsErrors,
        ...action.payload,
      };
    },
    setBillingAddressError: (state, action: PayloadAction<IFieldError>) => {
      state.billingAddressErrors = {
        ...state.billingAddressErrors,
        ...action.payload,
      };
    },
    setBillingAddressErrors: (
      state,
      action: PayloadAction<Record<string, IFieldError>>,
    ) => {
      state.billingAddressErrors = action.payload;
    },
    clearBillingAddress: (state) => {
      state.billingAddress = initialBillingAddress;
      state.initialBillingAddress = initialBillingAddress;
    },
    patchStorePreferences: (state, action: PayloadAction<Partial<IStorePreferences>>) => {
      const brandName =
        action.payload.brandName !== undefined
          ? action.payload.brandName
          : state.companySettings.brandName;
      const logoLinkUrl =
        action.payload.logoLinkUrl !== undefined
          ? action.payload.logoLinkUrl
          : state.companySettings.logoLinkUrl;
      const homepageTitle =
        action.payload.homepageTitle !== undefined
          ? action.payload.homepageTitle
          : state.companySettings.homepageTitle;

      state.companySettings = {
        ...state.companySettings,
        brandName,
        logoLinkUrl,
        homepageTitle,
      };
    },
    setStorePreferencesError: (state, action: PayloadAction<IFieldError>) => {
      state.storePreferencesError = { ...state.storePreferencesError, ...action.payload };
    },

    setIntercomAppId: (state, action: PayloadAction<string | null>) => {
      state.companySettings.intercomAppId = action.payload;
    },
    setIntercomAppIdError: (state, action: PayloadAction<string | undefined>) => {
      state.intercomAppIdError = action.payload;
    },
    setCustomCodeHeader: (state, action: PayloadAction<string | null>) => {
      state.companySettings.customCodeHeader = action.payload;
    },
    setCustomCodeBody: (state, action: PayloadAction<string | null>) => {
      state.companySettings.customCodeBody = action.payload;
    },
    setBrandLogo: (state, action: PayloadAction<IBrand['logo']>) => {
      state.brand.logo = action.payload;
    },
    setBrandFavicon: (state, action: PayloadAction<IBrand['favicon']>) => {
      state.brand.favicon = action.payload;
    },
    setBrand: (state, action: PayloadAction<IBrand>) => {
      state.brand = action.payload;
      state.initialBrand = action.payload;
    },
    patchBrand: (state, action: PayloadAction<Partial<IBrand>>) => {
      state.brand = {
        ...state.brand,
        ...action.payload,
      };
    },
    discardCompanyAndBrand: (state) => {
      state.company = state.initialCompany;
      state.companySettings = state.initialCompanySettings;
      state.billingAddress = state.initialBillingAddress;
      state.social = state.initialSocial;
      state.brand = state.initialBrand;
      state.companyErrors = {};
      state.companySettingsErrors = {};
      state.billingAddressErrors = {};
      state.storePreferencesError = {};
      state.socialErrors = {};
    },
    discardProductAndMargin: (state) => {
      state.companySettings = state.initialCompanySettings;
      state.marginValue = state.initialMarginValue;
      state.marginError = undefined;
    },
    discardOperationHours: (state) => {
      state.companySettings.hoursOperations =
        state.initialCompanySettings.hoursOperations;
    },
    discardPayment: (state) => {
      state.payout = state.initialPayout;
      state.payoutErrors = {};
      state.invoice = state.initialInvoice;
      state.invoiceErrors = {};
    },
    setResellerSettingsActiveCategoryTab: (
      state,
      action: PayloadAction<{ activeCategoryTab: number }>,
    ) => {
      state.activeCategoryTab = action.payload.activeCategoryTab;
    },
    setResellerSettingsCategoryDisabled: (
      state,
      action: PayloadAction<{ isEnabled: boolean; activeCategoryTab: number }>,
    ) => {
      state.companySettings.categories![action.payload.activeCategoryTab].isEnabled =
        action.payload.isEnabled;
    },
    setResellerSettingsCategoryChecked: (
      state,
      action: PayloadAction<{ checked: boolean; parentIndex: number; index: number }>,
    ) => {
      const { checked, parentIndex, index } = action.payload;

      const categories = state.companySettings.categories!;
      categories[parentIndex].items[index].isSelected = checked;
    },
    setResellerSettingsSuperInexpensive: (
      state,
      action: PayloadAction<{ isEnabled: boolean }>,
    ) => {
      state.companySettings.productsPricePoints!.isSuperInexpensive =
        action.payload.isEnabled;
    },
    setResellerSettingsBudget: (state, action: PayloadAction<{ isEnabled: boolean }>) => {
      state.companySettings.productsPricePoints!.isBudget = action.payload.isEnabled;
    },
    setCustomersReviewMockups: (state, action: PayloadAction<boolean>) => {
      state.companySettings.isReviewMockupsByCustomerAllowed = action.payload;
    },
    setCustomersReviewPantones: (state, action: PayloadAction<boolean>) => {
      state.companySettings.isReviewPantonesByCustomerAllowed = action.payload;
    },
    setResellerSettingsPremium: (
      state,
      action: PayloadAction<{ isEnabled: boolean }>,
    ) => {
      state.companySettings.productsPricePoints!.isPremium = action.payload.isEnabled;
    },
    setResellerSettingsStandard: (
      state,
      action: PayloadAction<{ isEnabled: boolean }>,
    ) => {
      state.companySettings.productsPricePoints!.isStandard = action.payload.isEnabled;
    },
    setHoursOperations: (
      state,
      action: PayloadAction<{ value: string | null; day: string; type: 'from' | 'to' }>,
    ) => {
      const result = state.companySettings.hoursOperations?.map((item) => {
        if (item.day === action.payload.day) {
          return {
            day: action.payload.day,
            from: action.payload.type === FROM ? action.payload.value : item.from,
            to: action.payload.type === TO ? action.payload.value : item.to,
          };
        }
        return item;
      });

      state.companySettings.hoursOperations = result as IHoursOperationsBack[];
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.companySettings.timezone = action.payload;
    },
    setMargin: (state, action: PayloadAction<string>) => {
      state.initialMarginValue = action.payload;
      state.marginValue = action.payload;
    },
    updateMargin: (state, action: PayloadAction<string>) => {
      state.marginValue = action.payload;
      try {
        MARGIN_VALIDATION.validateSync(action.payload);
        state.marginError = undefined;
      } catch (err: unknown) {
        const { message } = err as { message: string };
        state.marginError = message;
      }
    },
    setMarginError: (state, action: PayloadAction<string | undefined>) => {
      state.marginError = action.payload;
    },
    discardAllSettings: (state) => {
      state.company = state.initialCompany;
      state.companySettings = state.initialCompanySettings;
      state.billingAddress = state.initialBillingAddress;
      state.social = state.initialSocial;
      state.brand = state.initialBrand;
      state.companyErrors = {};
      state.companySettingsErrors = {};
      state.billingAddressErrors = {};
      state.socialErrors = {};
      state.companySettings = state.initialCompanySettings;
      state.marginValue = state.initialMarginValue;
      state.marginError = undefined;
      state.companySettings.hoursOperations =
        state.initialCompanySettings.hoursOperations;
      state.payout = state.initialPayout;
      state.payoutErrors = {};
    },
    setCard: (state, action: PayloadAction<ICreditCard | null>) => {
      state.card = action.payload ? { ...action.payload } : null;
    },
    setPrivacyPolicyHTML: (state, action: PayloadAction<string | null>) => {
      state.companySettings.privacyPolicyHTML = action.payload;
    },
    setTermsOfServiceHTML: (state, action: PayloadAction<string | null>) => {
      state.companySettings.termsOfServiceHTML = action.payload;
    },
    setCustomDomains: (state, action: PayloadAction<ICustomDomain>) => {
      state.companySettings.customDomain = action.payload;
    },
    checkHomepageProducts: (
      state,
      action: {
        payload: { publicId: string; value: IHomepageSelectedProduct };
      },
    ) => {
      const product = state.selectedHomepageProducts.find(
        (p) => p.publicId === action.payload.publicId,
      );
      if (product) {
        state.selectedHomepageProducts = state.selectedHomepageProducts.filter(
          (p) => p.publicId !== action.payload.publicId,
        );
      } else {
        state.selectedHomepageProducts.push(action.payload.value);
      }

      return state;
    },

    removeHomepageProductSelection: (
      state,
      action: {
        payload: { publicId: string };
      },
    ) => {
      state.selectedHomepageProducts = state.selectedHomepageProducts.filter(
        (p) => p.publicId !== action.payload.publicId,
      );

      return state;
    },

    removeHomepageAllProductsSelection: (state) => {
      state.selectedHomepageProducts = [];
      return state;
    },

    setHomepageToggle: (state, action: PayloadAction<boolean>) => {
      state.companySettings.isHomepageActive = action.payload;
    },

    setWhatWeOfferToggle: (state, action: PayloadAction<boolean>) => {
      state.companySettings.isWhatWeOfferActive = action.payload;
    },

    setAssignmentFlowToggle: (state, action: PayloadAction<boolean>) => {
      state.companySettings.assignmentFlowEnabled = action.payload;
    },

    setDefaultSorting: (state, action: PayloadAction<ProductSortingEnum>) => {
      state.companySettings.defaultSorting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      updatePayout.fulfilled,
      (state, action: PayloadAction<IUpdateFieldResultPayload>) => {
        const { key, value, error } = action.payload;
        state.payout = {
          ...state.payout,
          [key]: value,
        };
        state.payoutErrors = {
          ...state.payoutErrors,
          [key]: error,
        };
      },
    );
    builder.addCase(
      updateInvoice.fulfilled,
      (state, action: PayloadAction<IUpdateFieldResultPayload>) => {
        const { key, value, error } = action.payload;
        if (key === INVOICE_FIELDS.invoiceAsInPayout.name) {
          state.invoice = {
            ...state.invoice,
            [key]: value,
          };
          state.invoiceErrors = {};
          return;
        }
        state.invoice = {
          ...state.invoice,
          [key]: value,
        };
        state.invoiceErrors = {
          ...state.invoiceErrors,
          [key]: error,
        };
      },
    );
  },
});

export const settingsReducer = settings.reducer;
export const {
  setCard,
  setBrand,
  setSocial,
  setPayout,
  setMargin,
  setInvoice,
  setCompany,
  patchBrand,
  patchSocial,
  setTimezone,
  patchPayout,
  clearInvoice,
  patchCompany,
  patchInvoice,
  setBrandLogo,
  updateMargin,
  discardPayment,
  setSocialError,
  setMarginError,
  setPayoutError,
  setInvoiceError,
  setCompanyError,
  setBrandFavicon,
  setIntercomAppId,
  setCustomCodeBody,
  setBillingAddress,
  clearInvoiceError,
  setHoursOperations,
  setCompanySettings,
  setCustomCodeHeader,
  clearBillingAddress,
  patchBillingAddress,
  setHomepageSettings,
  patchCompanySettings,
  discardOperationHours,
  setIntercomAppIdError,
  patchStorePreferences,
  setBillingAddressError,
  setWhatWeOfferSettings,
  setBillingAddressErrors,
  discardCompanyAndBrand,
  discardProductAndMargin,
  setCompanySettingsError,
  setStorePreferencesError,
  setResellerSettingsBudget,
  setCustomersReviewMockups,
  setCustomersReviewPantones,
  setResellerSettingsPremium,
  setResellerSettingsStandard,
  setResellerSettingsCategoryChecked,
  setResellerSettingsCategoryDisabled,
  setResellerSettingsSuperInexpensive,
  setResellerSettingsActiveCategoryTab,
  discardAllSettings,
  setTermsOfServiceHTML,
  setPrivacyPolicyHTML,
  checkHomepageProducts,
  removeHomepageProductSelection,
  removeHomepageAllProductsSelection,
  setHomepageToggle,
  setWhatWeOfferToggle,
  setAssignmentFlowToggle,
  setDefaultSorting,
} = settings.actions;
