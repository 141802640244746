import { emailRegexp } from 'constants/validation';

export const FIELDS = {
  email: {
    label: 'Customer email',
    name: 'email',
    placeholder: 'Start typing email address',
    rules: {
      required: 'Email is required',
      pattern: {
        value: emailRegexp,
        message: "Email you entered doesn't seem to be valid",
      },
    },
  },
};
