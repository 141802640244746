import * as yup from 'yup';

import {
  CELL_PHONE_LENGTH,
  cellPhoneRegexp,
  EMAIL_LENGTH,
  emailRegexp,
  ERROR_MSG,
  noDoubleDashes,
} from 'constants/validation';

export const validationSchema = yup.object({
  firstName: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  lastName: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .min(2, ERROR_MSG.min(2))
    .max(20, ERROR_MSG.max(20))
    .required(ERROR_MSG.required),
  email: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .matches(emailRegexp, ERROR_MSG.email)
    .max(EMAIL_LENGTH, ERROR_MSG.max(80))
    .required(ERROR_MSG.required),
  phone: yup
    .string()
    .strict(true)
    .trim(ERROR_MSG.trim)
    .matches(cellPhoneRegexp, { message: ERROR_MSG.cellPhone, excludeEmptyString: true })
    .matches(noDoubleDashes, ERROR_MSG.cellPhoneDoubleDashes)
    .max(CELL_PHONE_LENGTH, ERROR_MSG.max(CELL_PHONE_LENGTH))
    .optional()
    .transform((value) => (value ? value : undefined)),
});
